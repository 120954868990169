import { ERROR_CODE } from '@cont/Login/constants';
import { readCookie } from './cookies';

export const PROD_COOKIE_LOG_KEY = 'un_log_enabled';

const ERROR_MESSAGES = {
  // [TODO] Change this sentence after updating to Next 13
  [ERROR_CODE.UN_IMG_LEGACY]: ({ src }) =>
    `${src} is a legacy image component. It is highly recommended to use @base/FutureImage for improved performance and Next.js 13 compatibility.`,
  [ERROR_CODE.UN_IMG_HEIGHT_NOT_FOUND]: ({ src }) => `${src}`,
  [ERROR_CODE.UN_IMG_WIDTH_NOT_FOUND]: ({ src }) => `${src}`,
  [ERROR_CODE.UN_IMG_SRC_NOT_FOUND]: () =>
    "Please check this page's code to determine the image being used without 'src' prop",
  [ERROR_CODE.UN_IMG_INVALID_CDN]: ({ src, validCDNs }) =>
    `${src} (Allowed CDN paths: ${validCDNs})`,
  [ERROR_CODE.UN_IMG_WIDTH_HEIGHT_NOT_INTEGER]: ({ src }) =>
    `${src} width/height should be integer values. Example: Pass width={32} instead of width="32px" or width="32"`
};

const logError = (id, ...args) => {
  const response = `${id}: ${
    ERROR_MESSAGES?.[id]?.(...args) ?? 'Fix the error.'
  }`;
  if (readCookie(PROD_COOKIE_LOG_KEY)) {
    // eslint-disable-next-line no-console
    console.error(response);
  }
  if (
    process.env.NODE_ENV === 'development' &&
    !process.env.DISABLE_IMAGE_ERRORS_ON_DEV
  ) {
    throw new Error(response);
  }
};

export default logError;
