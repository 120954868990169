export const ClassRoomLogo = 'classroom/classroom.svg';
export const UnIconsFooterLogo = 'un-icons-footer-logo.svg';
export const UnIconsLogoSrc = 'icons-logo.svg';
export const UnacademyLogo = 'logo.svg';
export const LogoCups = 'logoCups.svg';
export const MobileLogo = 'Mobile-Logo.svg';
export const UnIconsMobileLogo = 'un-icons-mobile-logo.svg';
export const UnWhiteLogo = 'un_white_logo.svg';

export const classRoomLogo = { src: ClassRoomLogo, height: 24, width: 161 };
export const withoutTextLogo = { src: LogoCups, height: 24, width: 24 };
export const regularLogo = { width: 161, height: 24 };
export const mobileLogo = { width: 24, height: 30 };
export const unIconsLogo = {
  footer: { src: UnIconsFooterLogo, height: 40, width: 104 },
  main: {
    src: UnIconsLogoSrc,
    height: 24,
    width: 161
  },
  mobile: {
    src: UnIconsMobileLogo,
    height: 40,
    width: 40,
    mobileWidth: 40
  }
};
