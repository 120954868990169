const getRedirectionURL = (selectedGoal, pathname) => {
  const { goalSlug, slug, uid } = selectedGoal;
  const href = '/goal/[goalSlug]/[goalUID]';
  const as = `/goal/${goalSlug || slug}/${uid}`;

  const routes = {
    '/': selectedGoal && { href, as },
    '/index': selectedGoal && { href, as },
    '/explore': selectedGoal && { href, as }
  };
  return routes[pathname];
};

export default getRedirectionURL;
