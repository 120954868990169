/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import NextImage from 'next/image';
import styled from '@emotion/styled';
import { fillerImageURL, imageLoader, validateImage } from '@utils/imageHelper';
import ComponentErrorBoundary from '@cont/ErrorBoundary/ErrorBoundary';
import { useEffect } from 'react';
import { readCookie } from '@utils/cookies';
import { PROD_COOKIE_LOG_KEY } from '@utils/errorLogger';

const StyledNextImage = styled(NextImage)`
  -webkit-user-drag: none;
  user-select: none;
`;

const SourceImage = ({ src, height, width, hasError, ...props }) => {
  useEffect(() => {
    if (!hasError) {
      validateImage({ src, width, height });
    }
  }, [hasError, height, src, width]);

  return (
    <StyledNextImage
      {...props}
      height={height}
      width={width}
      src={src || fillerImageURL}
      loader={imageLoader}
    />
  );
};

const Image = (props) => {
  return (process.env.NODE_ENV === 'production' &&
    readCookie(PROD_COOKIE_LOG_KEY)) ||
    process.env.NODE_ENV === 'development' ? (
    <ComponentErrorBoundary allowRender>
      <SourceImage {...props} />
    </ComponentErrorBoundary>
  ) : (
    <SourceImage {...props} />
  );
};

export default Image;
