import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { emptyObject } from '@constants/empty';
import useConstants from '@api/hooks/useConstants';
import { getCompeteStateBasedOnLaunchDate } from './utils';
import { COMPETE_STATES } from './constants';

const useCompeteConfig = () => {
  const {
    query: { goalUID },
    route
  } = useRouter();

  const { constants: { COMPETE_LAUNCH_INFO = emptyObject } = emptyObject } =
    useConstants();

  const {
    launch_date_time: launchDate,
    title,
    winner_prize_title: winnerPrizeTitle,
    winner_prize: showWinnerPrize
  } = COMPETE_LAUNCH_INFO?.[goalUID] ?? emptyObject;

  const [config, setConfig] = useState(() =>
    getCompeteStateBasedOnLaunchDate(launchDate)
  );

  useEffect(() => {
    setConfig(getCompeteStateBasedOnLaunchDate(launchDate));
  }, [launchDate]);

  const showCompeteBackground =
    config.isCompete &&
    route === '/goal/[goalSlug]/[goalUID]' &&
    (config.state === COMPETE_STATES.PRE_LAUNCH ||
      config.state === COMPETE_STATES.LIVE);

  return {
    ...config,
    showCompeteBackground,
    launchDate,
    title,
    winnerPrizeTitle,
    showWinnerPrize
  };
};

export default useCompeteConfig;
