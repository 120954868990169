import Icon from './Icon';
import { Spacings } from '../../styleConstants';

const LinkedIn = (props) => (
  <Icon
    size={Spacings.SPACING_6B}
    {...props}
    viewBox="0 0 24 24"
    path="M8.1476 9.23359C7.51412 9.23359 7 8.73344 7 8.1168C7 7.50015 7.51412 7 8.1476 7C8.78107 7 9.2952 7.50015 9.2952 8.1168C9.2952 8.73344 8.78173 9.23359 8.1476 9.23359ZM9.13116 16.9997H7.16385V10.0355H9.13116V16.9997ZM15.0334 16.9999H17.0007V12.7207C17.0007 9.39503 13.3258 9.51595 12.4103 11.1532V10.0357H10.443V16.9999H12.4103V13.452C12.4103 11.4811 15.0334 11.3197 15.0334 13.452V16.9999Z"
  />
);

export default LinkedIn;
