import styled from '@emotion/styled';
import { Fonts } from '@styles/index';

const StyledAnchor = styled.a`
  color: var(--color-text-secondary);
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  font-size: ${Fonts.SMALL};
  font-weight: ${Fonts.REGULAR};
  transition: color 200ms ease-in-out;

  &:hover {
    color: var(--color-text-primary);
  }
`;
const Link = ({ href, children, className, isRel = true }) => {
  return (
    <StyledAnchor
      className={className}
      href={href}
      rel={isRel ? 'noopener noreferrer' : ''}
      target="_blank"
    >
      {children}
    </StyledAnchor>
  );
};

export default Link;
