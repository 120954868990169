import { Spacings } from '@styles/index';
import Icon from './Icon';

const Facebook = (props) => (
  <Icon
    size={Spacings.SPACING_6B}
    {...props}
    viewBox="0 0 24 24"
    path="M12.8945 17.5V12.4824H14.6635L14.9284 10.5269H12.8945V9.27847C12.8945 8.71232 13.0597 8.3265 13.9124 8.3265L15 8.32605V6.57707C14.8119 6.55324 14.1663 6.5 13.4152 6.5C11.8471 6.5 10.7735 7.41129 10.7735 9.08481V10.5269H9V12.4824H10.7735V17.5H12.8945Z"
  />
);

export default Facebook;
