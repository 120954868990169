import Icon from './Icon';
import { Spacings } from '../../styleConstants';

const Instagram = (props) => (
  <Icon
    size={Spacings.SPACING_6B}
    {...props}
    pathFillRule="evenodd"
    pathClipRule="evenodd"
    viewBox="0 0 24 24"
    path="M12 6.5C10.5063 6.5 10.3193 6.50642 9.73263 6.533C7.73521 6.62467 6.62513 7.73292 6.53346 9.73217C6.50642 10.3193 6.5 10.5063 6.5 12C6.5 13.4937 6.50642 13.6812 6.533 14.2678C6.62467 16.2653 7.73292 17.3753 9.73217 17.467C10.3193 17.4936 10.5063 17.5 12 17.5C13.4937 17.5 13.6812 17.4936 14.2678 17.467C16.2634 17.3753 17.3763 16.2671 17.4665 14.2678C17.4936 13.6812 17.5 13.4937 17.5 12C17.5 10.5063 17.4936 10.3193 17.467 9.73263C17.3772 7.73704 16.2675 6.62513 14.2683 6.53346C13.6812 6.50642 13.4937 6.5 12 6.5ZM12.0009 7.49097C13.4694 7.49097 13.6436 7.49647 14.2239 7.52305C15.7144 7.59088 16.4106 8.29809 16.4784 9.77759C16.505 10.3574 16.51 10.5315 16.51 12C16.51 13.469 16.5045 13.6427 16.4784 14.2225C16.4101 15.7006 15.7157 16.4092 14.2239 16.477C13.6436 16.5036 13.4704 16.5091 12.0009 16.5091C10.5324 16.5091 10.3583 16.5036 9.77849 16.477C8.28432 16.4088 7.59178 15.6983 7.52395 14.222C7.49736 13.6423 7.49186 13.4685 7.49186 11.9996C7.49186 10.5311 7.49782 10.3574 7.52395 9.77713C7.59224 8.29809 8.28661 7.59043 9.77849 7.52259C10.3587 7.49647 10.5324 7.49097 12.0009 7.49097ZM9.1757 11.9999C9.1757 10.4402 10.4402 9.1757 11.9999 9.1757C13.5597 9.1757 14.8242 10.4402 14.8242 11.9999C14.8242 13.5601 13.5597 14.8247 11.9999 14.8247C10.4402 14.8247 9.1757 13.5597 9.1757 11.9999ZM12.0009 13.8329C10.9884 13.8329 10.1676 13.0125 10.1676 11.9996C10.1676 10.9871 10.9884 10.1662 12.0009 10.1662C13.0134 10.1662 13.8342 10.9871 13.8342 11.9996C13.8342 13.0125 13.0134 13.8329 12.0009 13.8329ZM14.2738 9.0645C14.2738 8.70013 14.5694 8.4045 14.9342 8.4045C15.2986 8.4045 15.5938 8.70013 15.5938 9.0645C15.5938 9.42888 15.2986 9.7245 14.9342 9.7245C14.5694 9.7245 14.2738 9.42888 14.2738 9.0645Z"
  />
);

export default Instagram;
