import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Fonts } from '@styles/index';
import { IconProps, ILinkTag } from './types';

const Link = styled(NextLink)<IconProps>`
  color: var(--color-text-primary);
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  font-size: ${Fonts.P2};
  font-weight: ${Fonts.REGULAR};
  ${({ $anchorStyle }) => $anchorStyle}
`;

const StyledDiv = styled.div<IconProps>`
  color: var(--color-text-primary);
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  font-size: ${Fonts.P2};
  font-weight: ${Fonts.REGULAR};
  cursor: pointer;
  ${({ $anchorStyle }) => $anchorStyle}
`;

const LinkTag = forwardRef(
  (
    {
      href,
      className,
      shallow,
      prefetch,
      children,
      onClick,
      as,
      newTab,
      anchorStyle,
      disabled,
      role,
      'data-id': dataId
    }: ILinkTag,
    ref
  ) => {
    if (disabled) {
      const onAnchorClick = (e) => {
        e.preventDefault();
        onClick(e);
      };
      return (
        <StyledDiv
          onClick={onAnchorClick}
          $anchorStyle={anchorStyle}
          className={className}
          role={role}
          ref={ref as React.RefObject<HTMLDivElement>}
          data-id={dataId}
        >
          {children}
        </StyledDiv>
      );
    }
    return (
      <Link
        passHref
        scroll
        shallow={shallow}
        prefetch={prefetch}
        ref={ref as React.RefObject<HTMLAnchorElement>}
        href={href}
        as={as}
        $anchorStyle={anchorStyle}
        className={className}
        onClick={onClick}
        target={newTab ? '_blank' : null}
        role={role}
        data-id={dataId}
      >
        {children}
      </Link>
    );
  }
);

LinkTag.displayName = 'Link';

LinkTag.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any)
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  shallow: PropTypes.bool,
  prefetch: PropTypes.bool,
  onClick: PropTypes.func,
  as: PropTypes.string,
  newTab: PropTypes.bool,
  anchorStyle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any)
  ]),
  disabled: PropTypes.bool,
  role: PropTypes.string,
  'data-id': PropTypes.string
};

LinkTag.defaultProps = {
  className: '',
  shallow: false,
  prefetch: false,
  onClick: () => {},
  as: '',
  href: '',
  newTab: false,
  anchorStyle: '',
  disabled: false,
  role: '',
  'data-id': undefined,
  children: null
};

export default LinkTag;
