/* eslint-disable import/prefer-default-export */
import { emptyObject } from '@constants/empty';
import { getDateDifferenceFromToday } from '@utils/dates';
import { commasIndianFormat, pluralize } from '@utils/textHelper';
import {
  ALL_EXAMS,
  ALL_STATES,
  BG_COLOR,
  COMPETE_LIVE_DAYS,
  COMPETE_POST_LAUNCH_DAYS,
  COMPETE_STATES
} from './constants';

type CompeteStateKeysType = keyof typeof COMPETE_STATES;
type CompeteStateValuesType = typeof COMPETE_STATES[CompeteStateKeysType];

// preLaunch < 0
// isLive === 0 || 21
// postLaunch <= 42

export const getCompeteState = (dateDiff: number): CompeteStateValuesType => {
  if (dateDiff < 0) {
    return COMPETE_STATES.PRE_LAUNCH;
  }

  if (dateDiff > COMPETE_LIVE_DAYS && dateDiff <= COMPETE_POST_LAUNCH_DAYS) {
    return COMPETE_STATES.POST_LAUNCH;
  }

  return COMPETE_STATES.LIVE;
};

export const getCompeteStateBasedOnLaunchDate = (launchDate) => {
  if (launchDate) {
    const dateDiff = getDateDifferenceFromToday(launchDate);

    if (dateDiff > COMPETE_POST_LAUNCH_DAYS) {
      return {
        isCompete: false,
        state: COMPETE_STATES.UNKNOWN
      };
    }
    const competeState = getCompeteState(dateDiff);
    return {
      isCompete: true,
      state: competeState
    };
  }
  return {
    isCompete: false,
    state: COMPETE_STATES.UNKNOWN
  };
};

export const getCompeteInfoKey = (goalUID: string) => {
  return `odin/v2/compete/info/?goal_uid=${goalUID}`;
};

export const getCompeteInfoKeyForAllMonths = (goalUID: string) => {
  return `odin/v2/compete/info/?goal_uid=${goalUID}&full_syllabus=true`;
};

export const getLeaderboardFiltersKey = (goalUID: string) => {
  return `odin/v1/compete/leaderboard/filters?goal_uid=${goalUID}&country_code=IN
  `;
};

export const getLeaderboardKey = (
  goalUID: string,
  limit = 10,
  examFilter = ALL_EXAMS,
  stateFilter = ALL_STATES,
  isOffsetAdded = true
) => {
  let defaultKey = 'odin/v1/compete/leaderboard/';

  if (goalUID) {
    defaultKey = `${defaultKey}?goal_uid=${goalUID}`;
  }
  if (isOffsetAdded) {
    defaultKey = `${defaultKey}&offset=0&limit=${limit}`;
  }
  if (examFilter && examFilter?.id !== -1) {
    defaultKey = `${defaultKey}&exam_preference_id=${examFilter.id}`;
  }
  if (stateFilter && stateFilter?.id !== -1) {
    defaultKey = `${defaultKey}&state_code=${stateFilter.code}`;
  }
  return defaultKey;
};

export const getCompeteRatingKey = (goalUID: string) => {
  return `api/contest-ratings/${goalUID}`;
};

export const getUserLevel = (currentRating, userLevels) => {
  if (currentRating == null || !userLevels || userLevels?.length === 0) {
    return null;
  }
  let i = 0;
  for (; i < userLevels.length; i += 1) {
    if (currentRating <= Number(userLevels[i]?.thresholdValue)) {
      break;
    }
  }
  if (i <= Number(userLevels.length - 1)) {
    return userLevels[i];
  }
  return userLevels[userLevels.length - 1];
};

export const getRankInfoString = (
  rank,
  exam
): {
  infoString: string;
  badgeImage: string;
  textColor: string;
  bgColor: string;
  borderColor?: string;
} => {
  if (rank <= 10) {
    return {
      badgeImage: '/compete/rank/rank-trophy-1.svg',
      infoString: `Top 10 · ${exam}`,
      textColor: 'var(--color-yellow-1)',
      bgColor: 'rgba(242, 185, 88, 0.2)'
    };
  }
  if (rank <= 100) {
    return {
      badgeImage: '/compete/rank/rank-trophy-2.svg',
      infoString: `Top 100 · ${exam}`,
      textColor: 'var(--color-blue-1)',
      bgColor: 'rgba(112, 180, 203, 0.2)'
    };
  }

  if (rank <= 1000) {
    return {
      badgeImage: '/compete/rank/rank-trophy-3.svg',
      infoString: `Top 1000 · ${exam}`,
      textColor: 'var(--color-brown-3)',
      bgColor: 'rgba(224, 151, 103, 0.2)'
    };
  }
  if (rank <= 2500) {
    return {
      badgeImage: '/compete/rank/rank-trophy-4.svg',
      infoString: `Top 2500 · ${exam}`,
      textColor: 'var(--color-violet-2)',
      bgColor: 'rgba(194, 192, 230, 0.2)'
    };
  }
  return {
    infoString: `#${rank} in ${exam}`,
    badgeImage: '/compete/rank/rank-trophy-5.svg',
    textColor: 'var(--color-text-secondary)',
    bgColor: 'var(--color-base-1)',
    borderColor: 'var(--color-divider)'
  };
};

export const isCompeteEnabled = (goalUID, constants) => {
  const competeList = constants?.COMPETE_GOALS ?? [];
  return competeList.includes(goalUID) ?? false;
};

export const getChapterCount = (subjects = []) => {
  const count = subjects.reduce((acc, subject) => {
    return acc + subject.totalChapters;
  }, 0);

  return count;
};

export const getMonthIcon = (isSoon, currentMonth, pastMonth) => {
  if (isSoon) {
    return '/compete/months/month-future.svg';
  }
  if (currentMonth) {
    return '/compete/months/month-current.svg';
  }
  if (pastMonth) {
    return '/compete/months/month-past.svg';
  }
  return '/compete/months/month-current.svg';
};

export const renderCount = (subjects) => {
  if (isFullSyllabus(subjects)) {
    return 'Full syllabus';
  }
  return pluralize(getChapterCount(subjects), 'chapter');
};

export const isFullSyllabus = (subjects = []) => {
  if (subjects.length > 0) {
    return subjects.every((subject) => subject.fullSyllabus);
  }
  return false;
};

export const MAX_GAMES_ALLOWED_FOR_SCHOLARSHIP = 3;

export const getIsCompeteHour = (startTime, endTime) => {
  const currentTime = Date.now();
  return currentTime >= startTime && currentTime <= endTime;
};

export const getCardInfo = ({
  isEligibleForScholarship = false,
  startTime,
  endTime,
  scholarship = emptyObject,
  isFirstGamePlayed = false,
  isLoggedIn = false,
  isAttemptAgain = false
}) => {
  if (!isLoggedIn) {
    return {
      desktopImg: 'compete/compete-to-unlock-rating.svg',
      mwebImg: 'compete/compete-to-unlock-rating-mweb.svg'
    };
  }
  const isCompeteHour = getIsCompeteHour(startTime, endTime);
  if (isCompeteHour) {
    return {
      desktopImg: 'compete/compete-hour.svg',
      mwebImg: 'compete/compete-hour-mweb.svg',
      isCompeteHour
    };
  }
  if (isEligibleForScholarship) {
    if (!isFirstGamePlayed) {
      return {
        desktopImg: 'compete/get-scholarship-new-user.svg',
        mwebImg: 'compete/get-scholarship-new-user-mweb.svg'
      };
    }
    if (scholarship.gamesPlayed === 1) {
      return {
        desktopImg: 'compete/scholarship-one-game-played.svg',
        mwebImg: 'compete/scholarship-one-game-played-mweb.svg'
      };
    }
    if (scholarship.gamesPlayed === 2) {
      return {
        desktopImg: 'compete/scholarship-two-games-played.svg',
        mwebImg: 'compete/scholarship-one-game-played-mweb.svg'
      };
    }
    if (isAttemptAgain) {
      return {
        desktopImg: 'compete/scholarship-attempt-again.svg',
        mwebImg: 'compete/scholarship-attempt-again-mweb.svg'
      };
    }
  }
  if (isFirstGamePlayed) {
    return {
      desktopImg: 'compete/compete-improve-rating.svg',
      mwebImg: 'compete/compete-improve-rating-mweb.svg'
    };
  }
  return {
    desktopImg: 'compete/compete-to-unlock-rating.svg',
    mwebImg: 'compete/compete-to-unlock-rating-mweb.svg'
  };
};

export const getNoRatingState = (meInfo) => {
  const { state, name, avatar } = meInfo;
  return {
    firstName: name,
    stateName: state,
    avatar,
    rank: null,
    rating: null
  };
};
