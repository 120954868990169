import { ERROR_CODE } from '@cont/Login/constants';
import logError from './errorLogger';

const STATIC_UACDN = 'https://static.uacdn.net';
const EDGE_UACDN = 'https://edge.uacdn.net';
const validCDNs = [
  `${STATIC_UACDN}/thumbnail/`,
  `${STATIC_UACDN}/web-cms/`,
  `${STATIC_UACDN}/educator_new/`,
  `${STATIC_UACDN}/generic-banner-uploads/`,
  `${STATIC_UACDN}/images/`,
  `${EDGE_UACDN}/static/thumbnail/`
];

/* eslint-disable operator-assignment */
export const getImage = (img) => `${process.env.PUBLIC}/static/images/${img}`;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getImageDimensions = (src, width) =>
  new Promise((resolve) => {
    const i = new Image();

    i.onload = () => {
      const scaleRatio =
        i.height > i.width ? i.width / i.height : i.height / i.width;
      let newScaleRatio = scaleRatio;
      while (newScaleRatio * i.height < width) {
        newScaleRatio = newScaleRatio * 1.05;
      }
      resolve({
        scaleRatio: newScaleRatio,
        height: i.height,
        width: i.width
      });
    };
    i.src = src;
  });

const formatSRCText = (src) => {
  if (src.includes('http')) {
    return `${src} (remote image: cmd+click link to view image)`;
  }
  return `${
    typeof window !== 'undefined' ? window.location.origin : ''
  }${getImage(src)} (local image: cmd+click link to view image)`;
};

const validatedImages = {};
export const validateImage = ({ width, height, src, type }) => {
  if (!src) {
    logError(ERROR_CODE.UN_IMG_SRC_NOT_FOUND);
    return;
  }
  const formattedSRCText = formatSRCText(src);

  // Preventing repeated checks
  if (validatedImages[src]) {
    return;
  }
  validatedImages[src] = true;

  if (type === 'LEGACY') {
    logError(ERROR_CODE.UN_IMG_LEGACY, { src: formattedSRCText });
  }

  if (!width) {
    logError(ERROR_CODE.UN_IMG_WIDTH_NOT_FOUND, { src: formattedSRCText });
  } else if (typeof width !== 'number') {
    logError(ERROR_CODE.UN_IMG_WIDTH_HEIGHT_NOT_INTEGER, {
      src: formattedSRCText
    });
  }
  if (!height) {
    logError(ERROR_CODE.UN_IMG_HEIGHT_NOT_FOUND, { src: formattedSRCText });
  } else if (typeof height !== 'number') {
    logError(ERROR_CODE.UN_IMG_WIDTH_HEIGHT_NOT_INTEGER, {
      src: formattedSRCText
    });
  }

  if (src.startsWith('http') && !validCDNs.some((v) => src.includes(v))) {
    logError(ERROR_CODE.UN_IMG_INVALID_CDN, {
      src: formattedSRCText,
      validCDNs: `${validCDNs.toString()}`
    });
  }
};
function urlEncodeQueryParams(data) {
  const params = Object.keys(data).map((key) =>
    data[key]
      ? `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      : ''
  );
  return params.filter((value) => !!value).join('&');
}

export const generateImageURLWithQueryParams = (
  source,
  queryParamsToAdd = {}
) => {
  const queryParamsWithMeta = {
    ...queryParamsToAdd
  };
  let endpoint = source;
  if (endpoint.startsWith('https://') || endpoint.includes('_next')) {
    try {
      const url = new URL(endpoint);
      const queryParams = Object.keys(queryParamsWithMeta);
      for (let i = 0; i < queryParams.length; i += 1) {
        const param = queryParams[i];
        const paramValue = queryParamsWithMeta[param];
        if (paramValue) url.searchParams.set(param, paramValue);
      }
      return url.href || endpoint;
    } catch {
      return endpoint;
    }
  }

  if (endpoint.startsWith('/')) endpoint = endpoint.slice(1);
  const queryString = urlEncodeQueryParams(queryParamsWithMeta);
  return `${getImage(endpoint)}${queryString ? `?${queryString}` : ''}`;
};

export const fillerImageURL = getImage('filler.png');
export const imageLoader = (options) => {
  const { src, width = null, quality = 75 } = options;
  const source = src;
  if (!source) return fillerImageURL;
  if (typeof source === 'object') return source.src || fillerImageURL;
  if (typeof source === 'string') {
    const result = generateImageURLWithQueryParams(source, {
      q: quality,
      auto: 'format,compress',
      w: width
    });
    return result;
  }
  return fillerImageURL;
};

const imageHelper = { getImage, toBase64 };

export default imageHelper;
