import Icon from './Icon';
import { Spacings } from '../../styleConstants';

const Youtube = (props) => (
  <Icon
    size={Spacings.SPACING_6B}
    {...props}
    viewBox="0 0 24 24"
    path="M8.50979 8.08183C10.1593 7.97294 13.8384 7.9725 15.4902 8.08183C17.2782 8.2005 17.4867 9.25115 17.5 12C17.4867 14.7537 17.2763 15.7999 15.4902 15.9182C13.8384 16.0275 10.1598 16.0271 8.50979 15.9182C6.72183 15.7995 6.51329 14.7489 6.5 12C6.51329 9.24626 6.72367 8.20005 8.50979 8.08183ZM10.6261 10.2241V13.7796L14.2927 11.9988L10.6261 10.2241Z"
  />
);

export default Youtube;
