import { captureException } from '@utils/sentry';
import { Component, cloneElement } from 'react';

class ComponentErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    captureException(error);
  }

  render() {
    const { hasError } = this.state;
    const { children, allowRender } = this.props;

    if (!allowRender && hasError) return '';
    return cloneElement(children, { hasError });
  }
}

export default ComponentErrorBoundary;
