import { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Link from '@base/Link/Link';
import Image from '@base/FutureImage';
import useWindowSize from '@hooks/useWindowSize';
import { getIsDarkModeOn } from '@stores/selectors/darkModeStatusSelectors';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import { mediaQuery } from '@styles/index';
import useCompeteConfig from '@cont/Compete/useCompeteConfig';
import { getRedirectionURLOnLogoClick } from '@cont/NewPreSubscribed/utils';
import { useRouter } from 'next/router';
import { readCookie } from '@utils/cookies';
import {
  classRoomLogo,
  mobileLogo,
  MobileLogo,
  regularLogo,
  UnacademyLogo,
  unIconsLogo,
  UnWhiteLogo,
  withoutTextLogo
} from './constants';

const LogoContainer = styled.div``;

const UnIconsLogo = styled(Image)`
  ${({ $isFooter }) =>
    $isFooter
      ? `
        height: ${unIconsLogo.footer.height};
        width: ${unIconsLogo.footer.width};
      `
      : `height: ${unIconsLogo.main.height};
      width: ${unIconsLogo.main.width};
      ${mediaQuery.uptoTablet} {
        height: ${unIconsLogo.mobile.height};
        width: ${unIconsLogo.mobile.width};
      }
  `}
`;

const RegularLogoWrapper = styled.div`
  display: block;
  ${mediaQuery.uptoTablet} {
    ${(props) => (!props.$isFooter ? 'display: none;' : '')}
  }
`;

const RegularLogo = styled(Image)`
  height: ${regularLogo.heigth};
  width: ${regularLogo.width};
`;

const MobileLogoWrapper = styled.div`
  display: none;
  ${mediaQuery.uptoTablet} {
    ${(props) => (props.$isFooter ? 'display: none;' : 'display: block;')}
  }
`;

const StyledMobileLogo = styled(Image)`
  height: ${mobileLogo.height};
  width: ${mobileLogo.width};
`;

const Logo = ({
  gridColumn,
  disabled,
  withoutText,
  isClassroom,
  isUnIcons,
  isReload,
  className,
  type
}) => {
  const isDarkModeOn = useDarkModeStatus(getIsDarkModeOn);
  const { isTablet, isMobile } = useWindowSize();

  const router = useRouter();
  const {
    query: { goalUID }
  } = router;

  const isFooter = type === 'footer';
  const isPageReload = async (e) => {
    e.preventDefault();
    let url = '/';
    const selectedGoalUid = goalUID || readCookie('selectedGoalUid');
    if (selectedGoalUid) {
      const redirection = await getRedirectionURLOnLogoClick({
        goalUID: selectedGoalUid,
        isReload
      });
      url = redirection.as;
    }
    window.location.href = url;
  };

  const unIconsLogoProps = useMemo(() => {
    if (isUnIcons) {
      if ((isTablet || isMobile) && !isFooter) return unIconsLogo.mobile;
      if (isFooter) return unIconsLogo.footer;
      return unIconsLogo.main;
    }
    return {};
  }, [isMobile, isTablet, isUnIcons, isFooter]);

  const { showCompeteBackground } = useCompeteConfig();

  const regularLogoProps = useMemo(() => {
    return {
      src: isDarkModeOn ? UnWhiteLogo : UnacademyLogo,
      height: regularLogo.height,
      width: regularLogo.width
    };
  }, [isDarkModeOn]);

  const mobileLogoProps = useMemo(
    () => ({
      src: MobileLogo,
      height: mobileLogo.height,
      width: mobileLogo.width
    }),
    []
  );

  const ClassroomLogo = useMemo(
    () => (
      <Image
        priority
        key={classRoomLogo.src}
        src={classRoomLogo.src}
        alt="Company Logo"
        data-analytics="global"
        className={className}
        $isFooter={isFooter}
        height={classRoomLogo.height}
        width={classRoomLogo.width}
      />
    ),
    [isFooter, className]
  );

  const WithoutTextLogo = useMemo(
    () => (
      <Image
        priority
        key={withoutTextLogo.src}
        src={withoutTextLogo.src}
        alt="Company Logo"
        data-analytics="global"
        className={className}
        $isFooter={isFooter}
        height={withoutTextLogo.height}
        width={withoutTextLogo.width}
      />
    ),
    [isFooter, className]
  );

  const UnIconsLogoComp = useMemo(
    () => (
      <UnIconsLogo
        priority
        key={unIconsLogoProps.src}
        src={unIconsLogoProps.src}
        alt="Company Logo"
        data-analytics="global"
        className={className}
        $isFooter={isFooter}
        height={unIconsLogoProps.height}
        width={unIconsLogoProps.width}
      />
    ),
    [unIconsLogoProps, className, isFooter]
  );

  const RegularLogoComp = useMemo(() => {
    const imageSrc =
      showCompeteBackground && !isFooter
        ? 'un_white_logo.svg'
        : regularLogoProps.src;

    return (
      <RegularLogoWrapper $isFooter={isFooter}>
        <RegularLogo
          priority
          key={regularLogoProps.src}
          src={imageSrc}
          alt="Company Logo"
          data-analytics="global"
          className={className}
          height={regularLogoProps.height}
          width={regularLogoProps.width}
        />
      </RegularLogoWrapper>
    );
  }, [regularLogoProps, isFooter, className, showCompeteBackground]);

  const MobileLogoComp = useMemo(() => {
    const imageSrc =
      showCompeteBackground && !isFooter
        ? 'un_white_mobile_logo.svg'
        : mobileLogoProps.src;

    return (
      <MobileLogoWrapper $isFooter={isFooter}>
        <StyledMobileLogo
          key={mobileLogoProps.src}
          src={imageSrc}
          alt="Company Logo"
          data-analytics="global"
          className={className}
          height={mobileLogoProps.height}
          width={mobileLogoProps.width}
        />
      </MobileLogoWrapper>
    );
  }, [mobileLogoProps, className, isFooter, showCompeteBackground]);

  const LogoComponent = () => (
    <LogoContainer>
      {isClassroom && ClassroomLogo}
      {withoutText && WithoutTextLogo}
      {isUnIcons && UnIconsLogoComp}
      {!isClassroom && !withoutText && !isUnIcons && RegularLogoComp}
      {!isClassroom && !withoutText && !isUnIcons && MobileLogoComp}
    </LogoContainer>
  );
  return (
    <Link
      href="/"
      as="/"
      anchorStyle={`
        align-self: center;
        height: ${isUnIcons ? '36px' : '24px'};
        grid-column: ${gridColumn};
        pointer-events: ${disabled ? 'none' : 'auto'};
        ${
          !isFooter &&
          `${mediaQuery.uptoTablet} {
            grid-column: 1/2;
          }
        `
        }
      `}
      onClick={isPageReload}
      className={className}
    >
      <LogoComponent />
    </Link>
  );
};

export default memo(Logo);

Logo.propTypes = {
  gridColumn: PropTypes.string,
  disabled: PropTypes.bool,
  withoutText: PropTypes.bool,
  isClassroom: PropTypes.bool,
  isUnIcons: PropTypes.bool,
  isReload: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string
};

Logo.defaultProps = {
  gridColumn: '1/5',
  disabled: false,
  withoutText: false,
  isClassroom: false,
  isUnIcons: false,
  isReload: false,
  className: '',
  type: ''
};
