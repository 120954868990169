import Facebook from '@icon/Facebook';
import YouTube from '@icon/Youtube';
import Twitter from '@icon/Twitter';
import Instagram from '@icon/Instagram';
import LinkedIn from '@icon/LinkedIn';

export const socialIconsLinks = [
  {
    name: 'facebook',
    link: 'https://www.facebook.com/unacademy/',
    icon: Facebook
  },
  {
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UCABe2FgVNv2hgBeMu2mySVg',
    icon: YouTube
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/unacademy',
    icon: Twitter
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/unacademy/',
    icon: Instagram
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/unacademy',
    icon: LinkedIn
  }
];

export const LearnerAppLinks = {
  PLAY_STORE:
    'https://play.google.com/store/apps/details?id=com.unacademyapp&referrer=utm_source%3DFooter%26utm_medium%3DWebApp%26utm_campaign%3DFooter%26',
  APP_STORE:
    'https://apps.apple.com/in/app/unacademy-learning-app/id1342565069?referrer%3Dutm_source%253DFooter%2526utm_medium%253DWebApp%2526utm_campaign%253DFooter%2526'
};

export const EducatorAppLinks = {
  PLAY_STORE:
    'https://play.google.com/store/apps/details?id=com.unacademy&referrer=utm_source%3DFooter%26utm_medium%3DWebApp%26utm_campaign%3DFooter%26',
  APP_STORE:
    'https://apps.apple.com/in/app/unacademy-educator-app/id1156375099?referrer%3Dutm_source%253DFooter%2526utm_medium%253DWebApp%2526utm_campaign%253DFooter%2526'
};

export const helpAndSupportLinks = [
  { label: 'User Guidelines', href: '/guidelines' },
  { label: 'Site Map', href: '/sitemap' },
  { label: 'Refund Policy', href: '/refund-policy' },
  { label: 'Takedown Policy', href: '/takedown-policy' },
  { label: 'Grievance Redressal', href: '/ugrc' }
];

export const popularGoalLinks = [
  { label: 'IIT JEE', href: '/goal/jee-main-and-advanced-preparation/TMUVD' },
  {
    label: 'UPSC',
    href: '/goal/upsc-civil-services-examination-ias-preparation/KSCGY'
  },
  { label: 'SSC', href: '/goal/ssc-exams-non-technical/VLEMN' },
  { label: 'CSIR UGC NET', href: '/goal/csir-ugc-net/BIZXQ' },
  { label: 'NEET UG', href: '/goal/neet-ug/YOTUH' }
];

export const trendingExamLinks = [
  { label: 'GATE', href: '/goal/gate-ese/PESHE' },
  {
    label: 'CAT',
    href: '/goal/cat-other-mba-entrance-tests/XNDUS'
  },
  { label: 'CA', href: '/goal/ca-intermediate/RXRWJ' },
  { label: 'NTA UGC NET', href: '/goal/nta-ugc-net-set-exams/TEWDQ' },
  { label: 'Bank Exams', href: '/goal/bank-exams/RTPSX' }
];

export const unacademyCentreLinks = [
  {
    label: 'Kota IIT JEE',
    href: '/goal/kota-jee-coaching/TMUVD/centre/Kota-Centre/NXG6I0UBPB' // pragma: allowlist secret
  },
  {
    label: 'Kota NEET UG',
    href: '/goal/kota-neet-coaching/YOTUH/centre/Kota-Centre/NXG6I0UBPB' // pragma: allowlist secret
  },
  {
    label: 'Kota Foundation',
    href: '/goal/kota-foundation-coaching/TPSBK/centre/Kota-Centre/NXG6I0UBPB' // pragma: allowlist secret
  },
  {
    label: 'Delhi UPSC',
    href: '/goal/UPSC-coaching/KSCGY/centre/Delhi-Centre/ZKSQFCU30C' // pragma: allowlist secret
  }
];

export const studyMaterialLinks = [
  { label: 'UPSC Study Material', href: '/content/upsc/study-material' },
  {
    label: 'NEET UG Study Material',
    href: '/content/neet-ug/study-material'
  },
  {
    label: 'CA Foundation Study Material',
    href: '/content/ca-foundation/study-material'
  },
  { label: 'JEE Study Material', href: '/content/jee/study-material' },
  { label: 'SSC Study Material', href: '/content/ssc/study-material' }
];
