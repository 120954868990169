import fetcher from '@api/fetchers/simpleFetcher';
import { PLATFORM } from '@constants/index';
import { getGenericBanner } from '@cont/PreSubscribed/GenericBannerList/apiHelper';
import { HOST_TYPE, getCMSHost, getHost } from '@utils/api-helper/host';

const defaultHost = getHost();
const CMSHost = getCMSHost();
const backendHost = getHost(HOST_TYPE.BACKEND_API_HOST);

export const fetchOnboardingGoals = (
  include_icons = true,
  include_platform_goals = true
) =>
  fetcher(
    `${defaultHost}v1/topology/topology-onboarding/?limit=20&scope=paid&include_icons=${include_icons}&include_platform_goals=${include_platform_goals}`
  );

export const fetchGoalInfo = (goalUID) =>
  fetcher(`${defaultHost}v1/topology/users/${goalUID}/self/`);

// lighter API to get required information related to self api
export const fetchGoalInfoLite = (goalUID, fields = null) =>
  fetcher(
    `${backendHost}v1/topology/users/${goalUID}/self/lite/${
      fields ? `?fields=${fields}` : ''
    }`
  );

export const fetchIconicSubscriptionInfo = (iconicGoalKey) =>
  fetcher(`${CMSHost}subscription-info/${iconicGoalKey}`);

export const fetchGoalBenefits = (goalBenefitsKey) =>
  fetcher(`${CMSHost}api/goal-benefits/${goalBenefitsKey}`);

export const fetchGoalPageStaticContent = (goalUID) =>
  fetcher(`${CMSHost}api/pre-subscribed-goal-info/${goalUID}`);

export const fetchDefaultStoreTabWhiteListedGoalUid = () =>
  fetcher(`${CMSHost}api/policies/store_default_tab_whitelisted_goal`);

export const fetchPriceHikeInfo = () => fetcher(`${CMSHost}active-price-hikes`);

export const fetchGoalPageBlocks = ({
  goalUID,
  limit = 25,
  offset = 0,
  platform
}) =>
  fetcher(
    `${defaultHost}v2/uplus/subscription/pre_subscription_home/?goal_uid=${goalUID}&limit=${limit}&offset=${offset}&v=11`,
    { headers: { 'x-platform': platform } }
  );

export const fetchGoalPageBlocksNew = ({
  goalUID,
  limit = 14,
  offset = 0,
  authorization,
  platform = PLATFORM.OTHER
}) =>
  fetcher(
    `${defaultHost}v1/feeds/${goalUID}/pre-subscription-home/?limit=${limit}&offset=${offset}`,
    { authorization, headers: { 'x-platform': platform } }
  );

export const fetchGoalHomeSubscriptionData = (goalUID) =>
  fetcher(
    `${defaultHost}v1/subscriptions/${goalUID}/pre-sub-plans/?iconic_flag=True&offers_limit=20`
  );
export const fetchGoalSecificCities = (goalUID) =>
  fetcher(`${defaultHost}v1/asgard/centre/offline/cities?goal_uid=${goalUID}`);
export const fetchGoalSubscriptionFeatures = (goalUID) =>
  fetcher(`${CMSHost}subscription-features/iconic-${goalUID}`);
export const fetchSocialProofing = () => {
  return fetcher(
    `${defaultHost}v1/uplus/feed/social-proofing/?type=pre_sub_web`
  );
};

export const fetchGoalFAQs = () => fetcher(`${defaultHost}v1/faq/?type=2`);

export const fetchIconsAnyfeed = (endpoint) =>
  fetcher(`${defaultHost}${endpoint}`);

export const fetchPlatformGoalBenefits = (platformGoalBenefitsKey) =>
  fetcher(`${CMSHost}api/platform-goal-benefits/${platformGoalBenefitsKey}`);

export const fetchGenericBannerInfo = (bannerUID) =>
  fetcher(`${defaultHost}${getGenericBanner(bannerUID)}`);

export const fetchPostPurchaseUrls = () =>
  fetcher(`${CMSHost}api/policies/postPurchaseRedirection`);

export const fetchAarambhEventDetails = () =>
  fetcher(`${CMSHost}api/aarambh-details`);
