import Icon from './Icon';
import { Spacings } from '../../styleConstants';

const Twitter = (props) => (
  <Icon
    size={Spacings.SPACING_6B}
    {...props}
    viewBox="0 0 24 24"
    path="M17.5 8.56547C17.0953 8.74636 16.6603 8.86818 16.2038 8.92309C16.67 8.64207 17.0279 8.19678 17.1961 7.66612C16.7603 7.92637 16.2772 8.11557 15.7629 8.21755C15.3518 7.77594 14.7647 7.5 14.1157 7.5C12.6586 7.5 11.588 8.86864 11.917 10.2894C10.042 10.1948 8.37917 9.2904 7.26588 7.91576C6.67463 8.93694 6.95925 10.2728 7.96392 10.9493C7.5945 10.9373 7.24617 10.8353 6.94229 10.665C6.91754 11.7176 7.66692 12.7023 8.75225 12.9215C8.43463 13.0083 8.08675 13.0286 7.73292 12.9603C8.01983 13.8628 8.85308 14.5195 9.84125 14.5379C8.8925 15.2869 7.69717 15.6214 6.5 15.4793C7.49871 16.1239 8.68533 16.5 9.9595 16.5C14.1496 16.5 16.5169 12.9372 16.3739 9.74169C16.8148 9.42099 17.1975 9.02092 17.5 8.56547Z"
  />
);

export default Twitter;
