import { centreContentTypes } from '@constants/index';

const getCoreSubscriptionUIDs = (user) => {
  const coreSubscriptions = user.userSubscriptionTimeline.filter(
    (subs) =>
      subs.contentTypeId === centreContentTypes.STANDALONE_CENTRE.toString() &&
      subs.timeRemaining > 0
  );
  return coreSubscriptions.map((subs) => subs.goalUid);
};

export default getCoreSubscriptionUIDs;
