/* eslint-disable import/prefer-default-export */

export const COMPETE_STATES = {
  UNKNOWN: 'UNKNOWN',
  PRE_LAUNCH: 'PRE_LAUNCH',
  LIVE: 'LIVE',
  POST_LAUNCH: 'POST_LAUNCH'
};

export const COMPETE_POST_LAUNCH_DAYS = 42;
export const COMPETE_LIVE_DAYS = 21;

export const APP_PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.unacademyapp&hl=en_IN&gl=US';

export const COMPETE_LANDING_PAGE = 'https://unacademy.com/compete';

export const COMPETE_VIDEO_LINK = 'https://youtu.be/Y-H5bvhbl1Y';

export const COMPETE_IMAGES = {
  BANNER_LEFT: 'https://static.uacdn.net/badge/compete/banner-left-image.png',
  BANNER_RIGHT: 'https://static.uacdn.net/badge/compete/banner-right-image.png',
  LOGO_NO_RINGS:
    'https://static.uacdn.net/badge/compete/compete-logo-no-rings.png',
  LOGO: 'https://static.uacdn.net/badge/compete/compete-logo.png'
};

export const TABS_DATA = [
  {
    id: 0,
    name: 'Rating Progress'
  },
  {
    id: 1,
    name: 'Leaderboard'
  },
  {
    id: 2,
    name: 'FAQs'
  }
];

export const ALL_EXAMS = {
  id: -1,
  name: 'All target exams'
};

export const ALL_STATES = {
  id: -1,
  code: '',
  name: 'All states'
};

export const BG_COLOR = {
  COLOR_1: '#F2B95833',
  COLOR_2: '#70B4CB33',
  COLOR_3: '#E0976733',
  COLOR_4: '#C2C0E633'
};

export const SYLLABUS_SECTIONS = {
  MONTH: 'month',
  SUBJECT: 'subject',
  TOPICS: 'topics'
};

export const BANNER_BANNER_VIEWED = 'Banner - Banner Viewed';
export const BANNER_BANNER_CLICKED = 'Banner - Banner Clicked';
export const RATINGS_SECTION_VIEWED = 'Ratings - Ratings Section Viewed';
export const LEADERBOARD_VIEWED = 'Goal Level Leaderboard - Viewed';
export const COMPETE_LANDING_PAGE_VIEWED = 'Compete - Landing Page Viewed';
export const SYLLABUS_SECTION_VIEWED = 'Compete - Syllabus Viewed';

export const RING_COLOR = {
  Beginner: '#FCD454',
  Hustler: '#FF8440',
  Pro: '#E653AD',
  Scholar: '#8B82FF',
  Champion: '#209FB1'
};
